<template>
	<v-container>
		<v-row>
			<v-col cols="4">
				<v-card>
          <v-card-text>
            <v-form>
              <v-text-field v-model="channel.name" label="Название" outlined></v-text-field>
              <v-text-field v-model="channel.link" label="Ссылка на канал" outlined :disabled="channel.hasOwnProperty('id')"></v-text-field>
              <v-text-field v-model="channel.admin_tg_id" label="Контакты" outlined></v-text-field>
              <v-select v-model="channel.platform" :items="['Telegram', 'Youtube']" outlined label="Платформа"></v-select>
              <v-textarea v-model="channel.comment" :items="['Telegram', 'Youtube']" outlined label="Комментарий"></v-textarea>
              <v-btn color="primary" @click="save()">Сохранить</v-btn>
            </v-form>
          </v-card-text>
				</v-card>
			</v-col>
			<v-col v-if="channel.id !== undefined" cols="8">
				<v-card>
          <v-card-text>
            <div class="ml-3 mt-3 mb-3">
              <create-link :channel="channel" :ad-channel-id="channel.id" @reload-links="loadLinks()"></create-link>
            </div>
          </v-card-text>
					<v-simple-table>
						<template v-slot:default>
							<thead>
								<tr>
									<th>Ссылка</th>
                  <th>Пост</th>
									<th>Подписчиков</th>
									<th>Дата публикации</th>
                  <th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(link, i) in links" :key="i">
									<td>{{link.link}}</td>
                  <td>
                    <div v-if="link.hasOwnProperty('post')">
                      <router-link :to="{ path: '/ad/posts/'+link.post.id}">{{link.post.title}}</router-link> <router-link :to="{ path: '/ad/posts/'+link.post.id}" target="_blank"><v-icon small>{{mdiOpenInNew}}</v-icon></router-link>
                    </div>
                    <div v-else>
                      А нет поста
                    </div>
                  </td>
									<td>{{link.subs}}</td>
									<td>{{link.created_at}}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import httpClient from '../../services/http.service'
import _ from 'lodash'
import CreateLink from './CreateLink'
import colors from 'vuetify/lib/util/colors'
import {mdiOpenInNew} from '@mdi/js'

export default {
  name: 'Channels',
  components:{
    CreateLink,
  },
  data(){
    return{
      mdiOpenInNew,
      links: [],
      colors,
    }
  },
  props: {
    channel: Object
  },
  mounted() {
    this.loadLinks()
  },
  methods:{
    loadLinks(){
      if (_.has(this.$route.params, 'id')){
        httpClient.get(`/exp-links/show?channel_id=${this.$route.params.id}&expand=post`).then(({data})=>{
          this.links = data
        })
      }else{
        console.log('no id - no links! load aborted')
      }
    },
    save(){
      if (_.has(this.channel, 'id')){
        httpClient.put('/ad-channels/'+this.channel.id, this.channel)
      }else{
        httpClient.post('/ad-channels', this.channel).then(({data})=>{
          this.channel.id = data.id
          this.$router.push('/ad/channels/'+data.id)
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
